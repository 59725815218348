import { useCallback, useState } from "react";
import { useRollbar } from "@rollbar/react";
import { useReCaptcha } from "next-recaptcha-v3";
import { Button } from "@tremor/react";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import getLanguage from "@/app/utils/getlanguage";
import translation from "./locale/index.json";

/**
 * React component for subscribing to a mailing list.
 * Provides a form for users to enter their email and subscribe.
 */
export default function Subscribe() {
  const [subscribe, setSubscribe] = useState("");
  const [subscribeSave, setSubscribeSave] = useState(false);
  const logger = useRollbar();
  const { executeRecaptcha } = useReCaptcha();
  const { locale } = useRouter();
  const lang = getLanguage(translation, locale);
  const content = lang.content;

  /**
   * Handles form submission for subscribing.
   * Validates email, generates ReCaptcha token, and sends API request.
   * Updates UI based on API response and logs any errors.
   * @param {Event} e - Form submission event
   */
  const handleSubmit = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (subscribe === "") {
        toast.error(content.errOne);
        return;
      }

      try {
        const token = await executeRecaptcha("form_submit");
        const response = await fetch("/api/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ contact: subscribe, token }),
        }).then((result) => result.json());

        if (response?.status === 200) {
          setSubscribeSave(true);
          toast.success(content.success);
        } else if (response?.status === 409 || response?.status === 401) {
          setSubscribeSave(false);
          toast.error(content.error);
        } else if (response?.status === 400) {
          setSubscribeSave(false);
          toast.error(response?.error);
        }
      } catch (err: any) {
        logger.warning(
          `Issue when submitting subscribe form: ${err.message}`,
          err
        );
        toast.error(`${content.errTwo}: ${err.message}`);
      }
    },
    [executeRecaptcha, logger, setSubscribeSave, subscribe, content]
  );

  return (
    <div className="">
      <div className="grid grid-cols-1 gap-2">
        <form onSubmit={handleSubmit}>
          <input
            placeholder={content.placeholder}
            className="border border-blue-500 rounded-full p-2 text-black w-[100%] sm:w-[80%]"
            type="text"
            name="subscribe"
            lang={locale}
            dir={locale === "ar" ? "rtl" : "ltr"}
            value={subscribe}
            onChange={(e) => setSubscribe(e.target.value)}
          />
          <Button
            className="ml-3 mt-2"
            size="xl"
            variant="secondary"
            type="submit"
            dir={locale === "ar" ? "rtl" : "ltr"}
          >
            {content.submit}
          </Button>
          {subscribeSave && (
            <div
              className="m-3 rounded-full font-bold"
              dir={locale === "ar" ? "rtl" : "ltr"}
            >
              {content.submited}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
