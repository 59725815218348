import { marked, MarkedExtension } from "marked";

/**
 * Asynchronously converts markdown content to HTML using the 'marked' library.
 * 
 * @param markdown - The markdown content to be converted to HTML.
 * @returns A Promise that resolves to the HTML content generated from the markdown.
 */
export default async function convertMarkdownToHTML(
  markdown: string
): Promise<string> {
  marked.use({
    async: true,
    pedantic: false,
    gfm: true,
    breaks: true,
    useNewRenderer: false
  });
  const walkTokens = (token: { type: string; depth: number; }): void => {
    if (token.type === "heading") {
      token.depth += 1;
    }
  };
  marked.use(walkTokens as MarkedExtension);
  const html = await marked.parse(markdown);
  return html;
}
