import { useRouter } from "next/router";

import getLanguage from "@/app/utils/getlanguage";
import translation from "./locale/index.json";
import { Callout } from "../Callout";

export enum CalloutVariant {
  "success" = "success",
  "warning" = "warning",
  "neutral" = "neutral",
  "error" = "error",
  "default" = "default"
}

export default function NotifySite(props: { info: string, variant: CalloutVariant }) {
  const { info, variant } = props;
  const router = useRouter();
  const { locale } = router;
  const lang = getLanguage(translation, locale);

  return (
    <div
      dir={locale === "ar" ? "rtl" : "ltr"}
      className="flex flex-row items-center justify-center md-5 mt-5"
    >
      <div className="flex flex-col gap-4 w-full">
        <Callout className=" bg-opacity-90" title={"Info"} variant={variant || CalloutVariant.default}>
          {info}
        </Callout>
      </div>
    </div>
  );
}
