const cleanUpText = (text: string): string => {
  if(!text) return text;
  return text
  .replace(/\r+/g, '\r')
  .replace(/\n+/g, '\n')
  // .replace(/\//g, '')
  // .replace(/"/g, '\"')
  // .replace(/'/g, '\'')
  .replace(/\s+/g, ' ')
  // .replace(/(["'])(.*?)\1|\s+/g, ' ')
    ;
}

export default cleanUpText;