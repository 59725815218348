import {
  Card,
  Grid,
  Col,
  Button,
  ProgressBar,
  TextInput,
  Title,
} from "@tremor/react";
import { ReCaptcha } from "next-recaptcha-v3";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { useRollbar } from "@rollbar/react";
import getLanguage from "@/app/utils/getlanguage";
import cleanUpText from "@/app/utils/cleanup.text";
import translation from "./locale/index.json";
import Typewriter from "@/app/components/Typewriter";
import { IConversationTask } from "@/app/interfaces/IConversationTask";
import convertMarkdownToHTML from "@/app/utils/markdown";
// import { MicrophoneButton } from "@/app/components/MicrophoneButton";
// import { AudioSwitch } from "@/app/components/AudioSwitch";

const header = {
  "Content-Type": "application/json",
};

export default function ServiceChat() {
  const { locale } = useRouter();
  const logger = useRollbar();
  const content = getLanguage(translation, locale);
  const [progress, setProgress] = useState(false);
  const [progressValue, setProgressValue] = useState(35);
  const [ask, setAsk] = useState("");
  const [typeString, setTypeString] = useState("");
  const [clear, setClear] = useState(false);
  const [conversation, setConversation] = useState<IConversationTask[]>([]);
  const [token, setToken] = useState<string | null>(null);
  const [score, setScore] = useState(0);
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    convertMarkdownToHTML(typeString).then((txt) => setMarkdown(txt));
  }, [typeString, markdown, setMarkdown]);

  useEffect(() => {
    if (token) {
      fetch("/api/auth/recaptcha", {
        headers: header,
        method: "POST",
        body: JSON.stringify({ token }),
      })
        .then(async (response) => {
          const responseRecaptcha = await response.json();
          if (responseRecaptcha.status === 200) {
            setScore(responseRecaptcha.score);
          }
        })
        .catch((err) => {
          logger.warn(`chat-bot recaptcha ${err?.message}`, err);
        });
    }
  }, [token, logger]);

  const click = () => {
    if (score < 0.5) {
      toast.error(`${content.dismiss} ${score}`);
      return;
    }
    setProgress(true);
    setProgressValue(55);
    const context = {
      ask: cleanUpText(`User language is "${locale}". ${ask}`),
      conversation,
    };
    fetch("/api/services/chat", {
      method: "POST",
      headers: header,
      body: JSON.stringify(context),
    })
      .then((data) => data.json())
      .then(async (json) => {
        const { status, data, error } = json;
        if (status >= 200 && status < 202) {
          setProgressValue(0);
          setAsk("");
          setProgress(false);
          let text = [];
          if (data.match(/FINISH_ACTION/gu)) {
            const _space = `\n ${content.finishText}`;
            text = _space?.replace(/\d+\. /g, "\n").split("\n") || [];
            setConversation([]);
          } else {
            const _space = `\n ${data}`;
            text = _space?.replace(/\d+\. /g, "\n").split("\n") || [];
            setConversation([
              ...conversation,
              {
                user: context.ask,
                assistant: data,
              },
            ]);
          }
          setTypeString(text?.join("<br>") || " ");
        } else {
          setProgressValue(0);
          setProgress(false);
          toast.error(error);
        }
      });
  };

  return (
    <>
      <ReCaptcha onValidate={setToken} action="submit" />
      <Card className="bg-[url('/images/girl.png')] bg-no-repeat bg-cover object-cover w-full h-full">
        <Title>{content.letsTalk}</Title>
        <Grid numItems={1} numItemsSm={2} className="gap-2 w-full">
          {/* <Col className="hidden sm:block">
          <AudioSwitch
            inputText={typeString}
            buttonText={content.btnAudio}
            language={locale}
          />
        </Col> */}

          {/* <Col className="hidden sm:block">
          <MicrophoneButton
            language={locale}
            textInput={(txt: string) => setAsk(txt)}
            buttonText={content.btnVoice}
            transcript={true}
          />
        </Col> */}

          <Col numColSpan={2} className="min-h-[20rem] bg-gray-100 bg-opacity-50 rounded-lg">
            <Typewriter text={markdown} clear={clear} maxHeight={""} />
          </Col>

          <Col numColSpan={2}>
            <TextInput
              onKeyDown={(e) => (e.keyCode === 13 ? click() : null)}
              autoFocus={false}
              value={ask}
              onChange={(e) => setAsk(e.target.value)}
            />
          </Col>

          <Col numColSpan={2} className="w-full">
            {progress ? (
              <ProgressBar value={progressValue} />
            ) : (
              <Button className="w-full" onClick={() => click()}>
                {content.btn}
              </Button>
            )}
          </Col>
        </Grid>
      </Card>
    </>
  );
}
