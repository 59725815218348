import { useTypewriter } from "@/hooks/useTypewriter";
import { useEffect, useRef } from "react";

const Typewriter = (props: {
  text: string;
  clear?: boolean;
  speed?: number;
  maxHeight: string;
  className?: string;
}) => {
  const { text, speed, clear, maxHeight, className } = props;
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [divRef.current?.scrollHeight]);

  const displayText = useTypewriter(text, speed, clear) || " ";

  return (
    <div
      ref={divRef}
      className={`${className} rounded-lg p-2 h-full min-h-[30rem] max-h-[${maxHeight}] w-full overflow-y-auto`}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
};

export default Typewriter;
